import {useSelector} from '../../store.js'
import {
  PRODUCT_DETAIL_BOM_PANEL,
  PRODUCT_SINGLE_URI_COMPONENT,
  BOM_LINK_TYPE,
} from '../../common/constants/Products.js'
import handleListSelection from '../../common/handleListSelection.js'
import Checkbox from '../../common/components/Checkbox.js'
import LinkButton from '../../common/components/Link/LinkButton.js'
import ButtonPrimary from '../../common/components/Button/ButtonPrimary.js'
import ButtonLink from '../../common/components/Button/ButtonLink.js'
import Quantity from '../../common/components/Quantity.js'
import {showProductComponentModal} from '../ProductListPage/Modals/ProductComponentModal.js'
import {showRemoveComponentModal} from '../ProductListPage/Modals/RemoveComponentModal.js'
import {isKitSelector, qtyThatCanBeBuiltSelector} from '../../data/products.js'
import {updateProductDetailForm} from './productDetailActions.js'
import {
  productDetailFormSelector,
  skuSelector,
  bomComponentsSelector,
  bomComponentSKUsSelector,
  areAllBOMComponentsSelectedSelector,
  areIndeterminateBOMComponentsSelectedSelector,
} from './productDetailSelectors.js'

export default function BOM() {
  const form = useSelector(productDetailFormSelector)
  const sku = useSelector(skuSelector)
  const bomComponents = useSelector(bomComponentsSelector)
  const bomComponentSKUs = useSelector(bomComponentSKUsSelector)
  const qtyThatCanBeBuilt = useSelector((state) =>
    qtyThatCanBeBuiltSelector(state, {sku}),
  )
  const areAllBOMComponentsSelected = useSelector(
    areAllBOMComponentsSelectedSelector,
  )
  const areIndeterminateBOMComponentsSelected = useSelector(
    areIndeterminateBOMComponentsSelectedSelector,
  )
  const isKit = useSelector((state) => isKitSelector(state, {sku}))

  const firstBOMComponent = form.selectedBOMComponentSKUs.length
    ? bomComponents.find(({sku}) => form.selectedBOMComponentSKUs.includes(sku))
    : null

  const firstSelectedComponentQuantity = firstBOMComponent
    ? firstBOMComponent.quantity
    : null

  return (
    <div className="row flex">
      <div className="medium-12 columns padding-left-0">
        <dl className="list">
          <dt className="list__title fs-01 lh-md">BOM Info</dt>
          <dd className="list__item fs-00 margin-top-10 margin-bottom-20">
            {isKit
              ? 'This product is a kit and cannot contain BOM components.'
              : bomComponents.length === 0
                ? 'This product is not currently a BOM.'
                : 'This product has BOM components.'}
          </dd>
          <dd className="list__item fs-00 margin-top-10 margin-bottom-20">
            Total BOMs that can be manufactured:{' '}
            <Quantity value={qtyThatCanBeBuilt} />
          </dd>
        </dl>
        {bomComponents.length > 0 && (
          <>
            <table className="table fs-00">
              <thead>
                <tr>
                  <th className="table__th table__th--sm w-1">
                    {bomComponentSKUs.length === 0 ? (
                      ''
                    ) : (
                      <Checkbox
                        className="margin-bottom-0"
                        checked={areAllBOMComponentsSelected}
                        indeterminate={areIndeterminateBOMComponentsSelected}
                        onClick={(checked) =>
                          updateProductDetailForm({
                            selectedBOMComponentSKUs: checked
                              ? bomComponentSKUs
                              : [],
                          })
                        }
                      />
                    )}
                  </th>
                  <th className="table__th table__th--md">Component Name</th>
                  <th className="table__th table__th--md">SKU</th>
                  <th className="table__th table__th--md align-right">
                    Phys On Hand
                  </th>
                  <th className="table__th table__th--md align-right">
                    Available
                  </th>
                  <th className="table__th table__th--md align-right">
                    Mfg ORD
                  </th>
                  <th className="table__th table__th--md align-right">
                    PO ReORD
                  </th>
                  <th className="table__th table__th--md align-right">
                    {form.selectedBOMComponentSKUs.length === 0 ? (
                      'Qty Needed for BOM'
                    ) : (
                      <ButtonLink
                        className="darker lh-md"
                        title="Bulk Update Quantity Needed for BOM"
                        onClick={() =>
                          showProductComponentModal(
                            sku,
                            form.selectedBOMComponentSKUs,
                            firstSelectedComponentQuantity,
                            BOM_LINK_TYPE,
                          )
                        }
                      >
                        Qty Needed for BOM
                      </ButtonLink>
                    )}
                  </th>
                  <th className="table__th table__th--md align-right">
                    {form.selectedBOMComponentSKUs.length === 0 ? (
                      ''
                    ) : (
                      <ButtonLink
                        className="no-underline"
                        title="Remove component"
                        onClick={() =>
                          showRemoveComponentModal(
                            sku,
                            form.selectedBOMComponentSKUs,
                            BOM_LINK_TYPE,
                          )
                        }
                      >
                        <span
                          className="i-trash fs-00"
                          aria-hidden="true"
                        ></span>
                      </ButtonLink>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody className="table__tbody table__tbody--lines">
                {bomComponents.map((bomComponent) => (
                  <tr key={bomComponent.sku}>
                    <td className="table__td">
                      <Checkbox
                        key={bomComponent.sku}
                        className="margin-bottom-0"
                        checked={form.selectedBOMComponentSKUs.includes(
                          bomComponent.sku,
                        )}
                        onClick={(checked, event) =>
                          updateProductDetailForm({
                            selectedBOMComponentSKUs: handleListSelection({
                              value: bomComponent.sku,
                              isSelected: checked,
                              isShiftKey: event.shiftKey,
                              selected: form.selectedBOMComponentSKUs,
                              list: bomComponentSKUs,
                            }),
                          })
                        }
                      />
                    </td>
                    <td className="table__td table__td--md">
                      <LinkButton
                        href={`#/${PRODUCT_SINGLE_URI_COMPONENT}/${encodeURIComponent(
                          bomComponent.sku,
                        )}/${PRODUCT_DETAIL_BOM_PANEL}`}
                        className="darker v-align-base"
                      >
                        <strong>{bomComponent.name}</strong>
                      </LinkButton>
                    </td>
                    <td className="table__td table__td--md">
                      <LinkButton
                        href={`#/product/${encodeURIComponent(
                          bomComponent.sku,
                        )}/${PRODUCT_DETAIL_BOM_PANEL}`}
                        className="darker v-align-base"
                      >
                        {bomComponent.sku}
                      </LinkButton>
                    </td>
                    <td className="table__td table__td--md align-right">
                      <Quantity value={bomComponent.total_on_hand} />
                    </td>
                    <td className="table__td table__td--md align-right">
                      <Quantity value={bomComponent.total_available} />
                    </td>
                    <td className="table__td table__td--md align-right">
                      <Quantity value={bomComponent.total_mfg_ordered} />
                    </td>
                    <td className="table__td table__td--md align-right">
                      <Quantity value={bomComponent.po_total_committed} />
                    </td>
                    <td className="table__td table__td--md align-right">
                      <ButtonLink
                        className="darker v-align-base"
                        onClick={() =>
                          showProductComponentModal(
                            sku,
                            [bomComponent.sku],
                            bomComponent.quantity,
                            BOM_LINK_TYPE,
                          )
                        }
                      >
                        <Quantity value={bomComponent.quantity} />
                      </ButtonLink>
                    </td>
                    <td className="table__td table__td--md align-right">
                      <ButtonLink
                        className="no-underline"
                        title="Remove component"
                        onClick={() =>
                          showRemoveComponentModal(
                            sku,
                            [bomComponent.sku],
                            BOM_LINK_TYPE,
                          )
                        }
                      >
                        <span
                          className="i-trash fs-00"
                          aria-hidden="true"
                        ></span>
                      </ButtonLink>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="align-center padding-top-15">
              <ButtonPrimary
                isOutlined
                size="sm"
                onClick={() =>
                  showProductComponentModal(
                    sku,
                    undefined,
                    undefined,
                    BOM_LINK_TYPE,
                  )
                }
              >
                Add a BOM component
              </ButtonPrimary>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
